import React, { useEffect, useRef } from "react";
import { Center, useAnimations, useGLTF, useTexture } from "@react-three/drei";
import animalsInfo from "../animalsInfos";

export default function AnimalCanvas({ hoveredNumber, date, discover }) {
  const animalRef = useRef()

  const { nodes, materials, animations } = useGLTF("./gltf/Chat Anim2.glb")
  const { actions: catActions } = useAnimations(animations, animalRef)
  const dogmap = useTexture("./textures/LP_ShibaInu_1002_Diffuse.jpg")
  const catmap = useTexture("./textures/Fat_cat_calico_base.webp")
  catmap.flipY = false;


  const matcamanimals = useTexture("./textures/7A7A7A_D9D9D9_BCBCBC_B4B4B4.jpg")

  const { nodes: n2, materials: m2, animations: a2 } = useGLTF("./gltf/Chien Anim.glb")
  const { actions: dogActions } = useAnimations(a2, animalRef)

  const animationsNames = ["jump", "Dog_Walk", "Chien1", "Dog_Walk"];
  const animationsNamesCat = ["Sit", "static", "walk", "Sit"];

  // Vérifiez que l'index est valide
  const animalInfo = animalsInfo[date - 1];
  const breed = animalInfo ? animalInfo.breed : null;

  useEffect(() => {
    if (!breed) return; // Assurez-vous que breed est défini

    if (hoveredNumber - 1 >= animationsNames.length || hoveredNumber <= 0) {
      if (breed === "Chien") {
      }
      return;
    }

    if (breed === "Chien") {
      dogActions[animationsNames[hoveredNumber - 1]].reset().fadeIn(1).play();

      return () => dogActions[animationsNames[hoveredNumber - 1]].fadeOut(1);
    } else if (breed === "Chat") {
      // Logique pour le chat
      catActions[animationsNamesCat[hoveredNumber - 1]].reset().fadeIn(1).play();
      return () => catActions[animationsNamesCat[hoveredNumber - 1]].fadeOut(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredNumber, dogActions, catActions, breed]);



  return (
    <>
      <group
        ref={animalRef}
        position={[0, -0.9, 0]}

      >

        {breed === "Chat" ? (
          <group
            scale={0.85}
            rotation={[0, 0.5, 0]}
            visible={!discover}
          >
            <skinnedMesh
              name="Mesh_Chat"
              geometry={nodes.Mesh_Chat.geometry}
              skeleton={nodes.Mesh_Chat.skeleton}
            >
              <meshMatcapMaterial matcap={matcamanimals} map={catmap} /></skinnedMesh>
            <primitive object={nodes.c_pos} />
            <primitive object={nodes.c_foot_ikr} />
            <primitive object={nodes.c_leg_poler} />
            <primitive object={nodes.c_foot_ikl} />
            <primitive object={nodes.c_leg_polel} />
            <primitive object={nodes.root_refx} />
            <primitive object={nodes.c_foot_ik_dupli_001l} />
            <primitive object={nodes.c_leg_pole_dupli_001l} />
            <primitive object={nodes.c_foot_ik_dupli_001r} />
            <primitive object={nodes.c_leg_pole_dupli_001r} />
          </group>
        ) : breed === "Chien" ? (
          <group
            scale={0.8}
            rotation={[0, 1, 0]}
            visible={!discover}

          >
            <group name="Dog">
              <group name="Dog">
                <skinnedMesh
                  name="Dog"
                  geometry={n2.Dog.geometry}
                  skeleton={n2.Dog.skeleton}
                  material={m2.Dog}
                >
                  <meshMatcapMaterial matcap={matcamanimals} map={m2['lambert1.001'].map} /></skinnedMesh>
              </group>
            </group>
            <primitive object={n2.c_pos} />
            <primitive object={n2.c_foot_ikr} />
            <primitive object={n2.c_leg_poler} />
            <primitive object={n2.c_foot_ikl} />
            <primitive object={n2.c_leg_polel} />
            <primitive object={n2.root_refx} />
            <primitive object={n2.c_foot_ik_dupli_001l} />
            <primitive object={n2.c_leg_pole_dupli_001l} />
            <primitive object={n2.c_foot_ik_dupli_001r} />
            <primitive object={n2.c_leg_pole_dupli_001r} />
          </group>
        ) : null}
      </group>
    </>
  );
}
