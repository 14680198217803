import gsap from "gsap";
import { useEffect } from "react";

export default function Dots({ color, animate = false }) {
  useEffect(() => {
    if (animate) {
      gsap.to(".dots", {
        opacity: "100%",
        duration: 0.3,
        stagger: 0.2,
        yoyo: true,
        repeat: -1,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <svg
      width="100%"
      viewBox="0 0 80 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.10365 1.00407C3.20173 0.925924 4.14783 0.445209 4.35063 0.380989C10.1204 -1.44618 18.5245 3.71077 18.9635 7.10466C19.7233 12.9789 8.40631 12.5682 4.30652 10.9774C-1.47729 8.7335 -0.980107 3.40023 3.10365 1.00407Z"
        fill={color}
        opacity={animate ? 0 : 1}
        className={animate ? "dots" : ""}
      />
      <path
        transform="translate(30,0)"
        d="M6.10365 1.00407C3.20173 0.925924 4.14783 0.445209 4.35063 0.380989C10.1204 -1.44618 18.5245 3.71077 18.9635 7.10466C19.7233 12.9789 8.40631 12.5682 4.30652 10.9774C-1.47729 8.7335 -0.980107 3.40023 3.10365 1.00407Z"
        fill={color}
        opacity={animate ? 0 : 1}
        className={animate ? "dots" : ""}
      />
      <path
        transform="translate(60,0)"
        d="M3.10365 1.00407C3.20173 0.925924 4.14783 0.445209 4.35063 0.380989C10.1204 -1.44618 18.5245 3.71077 18.9635 7.10466C19.7233 12.9789 8.40631 12.5682 4.30652 10.9774C-1.47729 8.7335 -0.980107 3.40023 3.10365 1.00407Z"
        fill={color}
        opacity={animate ? 0 : 1}
        className={animate ? "dots" : ""}
      />
    </svg>
  );
}
