import { Howl } from "howler";

export default class SoundManager {
    static instance;
    isPlaying = null;
    hasStarted = null;
    soundsList = null;
    currentSound = null;

    constructor() {
        if (SoundManager.instance) {
            return SoundManager.instance;
        }
        this.hasStarted = true;
        const snds = this.loadSounds();
        SoundManager.instance = this;
    }

    async startXp(key, animationStart = null) {
        // Définit l'état des sons
        this.soundsList[key].howl.volume(0);
        this.soundsList[key].howl.play();
        this.soundsList[key].howl.fade(0, this.soundsList[key].volume, 500);
        this.currentSound = this.soundsList[key].howl;
        this.isPlaying = true;
        if (animationStart) {
            animationStart();
        }
    }

    transitionMusic(room) {
        if (this.currentSound) {
            this.currentSound.fade(this.currentSound._volume, 0, 1000);
            this.currentSound.stop(); // Arrête l'instance audio actuelle
        }

        if (this.soundsList[room] && this.soundsList[room].howl) {
            this.soundsList[room].howl.play();
            if (this.isPlaying) {
                this.soundsList[room].howl.fade(0, this.soundsList[room].volume, 1000);
            }
            this.currentSound.current = this.soundsList[room].howl;
        }
    }

    toggleSound(animationStop = () => { }, animationStart = () => { }) {
        if (this.soundsList) {
            if (this.isPlaying) {
                for (const [key, value] of Object.entries(this.soundsList)) {
                    this.soundsList[key].howl.fade(this.soundsList[key].howl._volume, 0, 500);
                }
                animationStop();
            } else {
                animationStart();
                for (const [key, value] of Object.entries(this.soundsList)) {
                    this.soundsList[key].howl.fade(0, this.soundsList[key].volume, 500);
                }
            }
            this.isPlaying = !this.isPlaying;
        }
    }

    playSingleSound(sound) {
        if (this.soundsList && this.isPlaying && this.soundsList[sound]) {
            this.soundsList[sound].howl.volume(this.soundsList[sound].volume);
            this.soundsList[sound].howl.play();
        }
    }

    stopSingleSound(sound) {
        if (this.soundsList && this.isPlaying && this.soundsList[sound]) {
            this.soundsList[sound].howl.fade(this.soundsList[sound].howl._volume, 0, 1000);
            this.soundsList[sound].howl.stop();
        }
    }
    async loadSounds() {
        const tmp = {};

        const loadAudio = async (key, src, volume, loop = false) => {
            tmp[key] = {
                howl: new Howl({
                    src: [src],
                    loop: loop,
                    volume: volume,
                }),
                volume: volume
            }
        };

        await Promise.all([
            loadAudio("start", "/sounds/startexperience.mp3", 0.3, false),
            loadAudio("bark", "/sounds/bark.mp3", 0.5, false),
            loadAudio("opening", "/sounds/opening.mp3", 0.5, false),
            loadAudio("meow", "/sounds/meow.mp3", 0.5, false),

        ]);

        this.soundsList = tmp;
    }
}
