import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { LoopOnce } from "three";

export default function DogModel() {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("./gltf/Chien Anim.glb")
  const { actions } = useAnimations(animations, group)
  const matcamanimals = useTexture("./textures/7A7A7A_D9D9D9_BCBCBC_B4B4B4.jpg")

  const [animIndex, setAnimIndex] = useState(0);

  useEffect(() => {
    actions["jump"].loop = LoopOnce;
    actions["jump"].clampWhenFinished = true;
    actions["Chien1"].play();

    const interval = setInterval(
      () => setAnimIndex((animIndex) => animIndex + 1),
      5000
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (animIndex % 2 === 0) {
      actions["jump"].fadeOut(1);
      actions["jump"].reset().fadeIn(1).play();
    } else {
      actions["Chien1"].fadeOut(1);
      actions["Chien1"].reset().fadeIn(1).play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animIndex]);

  return (
    <>

      <group name="rig"
        ref={group}
        scale={0.45}
      >
        <group name="Dog">
          <skinnedMesh
            name="Dog"
            geometry={nodes.Dog.geometry}
            skeleton={nodes.Dog.skeleton}
          >
            <meshMatcapMaterial matcap={matcamanimals} map={materials['lambert1.001'].map} /></skinnedMesh>
        </group>
        <primitive object={nodes.c_pos} />
        <primitive object={nodes.c_foot_ikr} />
        <primitive object={nodes.c_leg_poler} />
        <primitive object={nodes.c_foot_ikl} />
        <primitive object={nodes.c_leg_polel} />
        <primitive object={nodes.root_refx} />
        <primitive object={nodes.c_foot_ik_dupli_001l} />
        <primitive object={nodes.c_leg_pole_dupli_001l} />
        <primitive object={nodes.c_foot_ik_dupli_001r} />
        <primitive object={nodes.c_leg_pole_dupli_001r} />
      </group >
    </>
  );
}
