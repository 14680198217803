import { Vector3 } from "three";
import Modelisation from "./Modelisation";
import Sky from "./Sky";
import { useThree, useFrame } from "@react-three/fiber";
import {
  EffectComposer,
  Bloom,
  DepthOfField,
} from "@react-three/postprocessing";

export default function Scene({ discover, onCameraAnimationEnd, date, isCameraLocked }) {
  const { scene } = useThree();
  const clamp = (value, min, max) => Math.max(min, Math.min(max, value));
  const isMobile = window.innerWidth <= 768; // Définissez une largeur maximale pour considérer un appareil comme mobile

  useFrame(({ camera }) => {
    if (isCameraLocked) {
      const distanceFromCenter = camera.position.y; // Utilisez la distance de la caméra au centre
      // Calcule la densité du brouillard en fonction de la distance
      let fogDensity = (1. - (distanceFromCenter / 12.5)) * 0.047 + 0.003; // Clamp entre 0.003 et 0.09
      fogDensity = clamp(fogDensity, 0.003, 0.05);
      scene.fog.density = fogDensity; // Applique la densité d
    } else {

      const distanceFromCenter = camera.position.y; // Utilisez la distance de la caméra au centre
      // Calcule la densité du brouillard en fonction de la distance
      let fogDensity = (1. - (distanceFromCenter / 31.5)) * 0.047 + 0.003; // Clamp entre 0.003 et 0.09
      fogDensity = clamp(fogDensity, 0.003, 0.05);
      scene.fog.density = fogDensity; // Applique la densité du brouillard à la scène
    }
  });

  return (
    <>
      {!isMobile && (
        <EffectComposer>
          <Bloom luminanceThreshold={1} intensity={0.7} />
        </EffectComposer>
      )}
      <fogExp2 attach="fog" args={["#91d3ff"]} />

      <Sky />
      <Modelisation
        key={date}
        discover={discover}
        onCameraAnimationEnd={onCameraAnimationEnd}
        date={date}
        isCameraLocked={isCameraLocked}
      />
    </>
  );
}
