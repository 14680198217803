import { useFrame } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import fragmentShader from "./shaders/fragmentShader";
import vertexShader from "./shaders/vertexShader";
import { Vector2, BackSide } from "three";

export default function Sky() {
  const sky = useRef();

  //sky shader material uniforms
  const uniforms = useMemo(
    () => ({
      uTime: { value: 0.0 },
      uValue1: { value: -0.75 },
      uValue2: { value: 3.84 },
      uValue3: { value: 5 },
      uValue4: { value: 0 },
      uTimeFactor1: { value: 0.37 },
      uTimeFactor2: { value: 1 },
      uTimeFactor3: { value: 0.39 },
      uTimeFactor4: { value: 0.28 },
      uScale: { value: 3.84 },
      uFactor1: { value: new Vector2(8.1, -8) },
      uFactor2: { value: new Vector2(-8, 1.6) },
      uFactor3: { value: new Vector2(6.6, 2.0) },
      uFactor4: { value: new Vector2(-0.7, 6.7) },
      uProgress: { value: 0.4 },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //update time in sky shader material
  useFrame((state) => {
    const { clock } = state;
    sky.current.material.uniforms.uTime.value = clock.elapsedTime;
  });

  return (
    <mesh ref={sky} scale={2} position-y={-20} rotation-y={Math.PI * 0.75}>
      <cylinderGeometry args={[50, 50, 600, 32, 1, true]} />
      <shaderMaterial
        fragmentShader={fragmentShader}
        side={BackSide}
        vertexShader={vertexShader}
        uniforms={uniforms}
      />
    </mesh>
  );
}
