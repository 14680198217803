const animalsInfos = [
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?id=28967",
    picture: "./png/shiva.webp",
    name: "SHIVA",
    breed: "Chien",
    isFemale: false,
    text: (
      <span>
        Shiva et ses frères et sœurs n’ont pas eu un début de vie facile. Nés
        dans des conditions difficiles, sans amour ni soin, ils étaient destinés
        à un avenir sombre. Leur maman, encore toute jeune, était attachée,
        privée de tendresse, et eux aussi étaient voués à être vendus sans
        scrupule.
        <br />
        <br />
        Pour Shiva, l’idéal serait une maison avec jardin, des humains
        bienveillants et des balades en nature, où il pourrait enfin découvrir
        ce que signifie être vraiment chez soi. Adopter Shiva, c’est lui offrir la
        vie et l’amour qu’il mérite depuis toujours.
      </span>
    ),
    title: (
      <span>
        Pourquoi <br />
        adopter ?
      </span>
    ),
    tips: (
      <span>
        Adopter un animal, c’est offrir une seconde chance à un compagnon qui
        attend une famille aimante. Les refuges de la FBB regorgent de chiens et
        de chats en quête d’un foyer chaleureux. En adoptant, vous sauvez deux
        vies, celle de l'adopté, et celle de celui qui pourra prendre sa place
        au refuge.
      </span>
    ),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_0_a_12_mois&id=29658",
    picture: "./png/aquarelle.webp",
    name: "Aquarelle",
    breed: "Chat",
    isFemale: false,
    text: (
      <span>
        Aquarelle est un adorable petit compagnon, inspirant comme une œuvre d’art.
        Né sur un camping, il n’a pas connu la chaleur d’un foyer, sa maman
        errant seule, sans personne pour s’occuper d’elle. Heureusement, le
        refuge a recueilli toute la petite famille pour leur offrir sécurité et
        réconfort.
        <br />
        <br />
        Maintenant, Aquarelle attend sa vraie chance : une famille aimante où il
        pourra enfin s’épanouir et partager toute sa douceur.
      </span>
    ),
    title: (
      <span>
        Avant
        <br />
        d'adopter
      </span>
    ),
    tips: (
      <span>
        Évaluez votre style de vie : Temps, espace, et ressources pour répondre aux besoins d’un animal. Optez pour un compagnon adapté à votre quotidien. Ayez conscience du budget : Nourriture, soins vétérinaires et accessoires. Un animal, c’est des années de responsabilité. Pensez à l’avenir !
      </span>
    ),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_1_a_5_ans&id=25656",
    picture: "./png/chasu.webp",
    name: "CHASU",
    breed: "Chat",
    isFemale: true,
    text: (
      <span>
        Chasu vivait paisiblement auprès d’une dame qui l’aimait beaucoup, jusqu’au jour où celle-ci a disparu, laissant Chasu et ses compagnons pris en charge par la Fondation. Bien entourée aujourd’hui, Chasu rêve pourtant de retrouver la sérénité d’un vrai foyer.
        <br />
        <br />
        Elle attend maintenant une famille douce et aimante pour lui offrir la tranquillité et l’amour auxquels elle était habituée. Qui voudra lui ouvrir son cœur ?
      </span>
    ),
    title: (
      <span>
        Les règles
        <br />
        de bases chat
      </span>
    ),
    tips: (
      <span>
        Avant d'accueillir un chat, veillez à pouvoir répondre à tous ses besoins : litière, gamelles, jouets, cachettes, arbre à chat, fenêtres sécurisées...
      </span>
    ),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&id=19548",
    picture: "./png/solo.webp",
    name: "SOLO",
    breed: "Chien",
    isFemale: false,
    text: (
      <span>
        Ce chien courageux a été sauvé d’une situation terrible, détenu avec des dizaines d’autres chiens, sans soin ni nourriture. Aujourd’hui en pleine forme, il rêve de découvrir la chaleur d’un foyer. Un peu timide et indépendant, il s’épanouira au contact d’un autre chien qui saura le rassurer. Une fois en confiance, il devient un compagnon actif et joueur, prêt à vivre une nouvelle vie pleine de bonheur.
      </span>
    ),
    title: (
      <span>
        Les soins <br />
        quotidiens
      </span>
    ),
    tips: (
      <span>
        Pour le bien-être de votre chien, veilliez à lui apporter des soins quoitidiens : assurer les promenades, vérifier ses dents, ses poils, ses yeux, ses oreilles et lui apporter une alimentation adaptée et de qualité
      </span>
    ),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_5_a_10_ans&id=27797",
    picture: "./png/neige.webp",
    name: "NEIGE",
    breed: "Chat",
    isFemale: true,
    text:
      <span>Cette belle "panthère des neiges" a passé des années dans une maison négligée. Aujourd’hui pleine d’énergie, elle est prête pour une nouvelle vie joyeuse et chaleureuse, entourée de l’amour d’une famille. Avec son caractère dynamique et enjoué, elle attend des compagnons prêts pour de belles aventures à ses côtés !</span>,
    title: (<span>L'alimentation</span>),
    tips:
      (<span>Quelle soit industrielle ou ménagère, sous forme de croquettes ou de pâtées (ou mixte), le principal est que l’alimentation du chat respecte ses besoins nutritionnels. Certains chats, en raison de leur état de santé peuvent avoir besoin d’une alimentation médicalisée, prescrite par leur véto.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?&id=11470&sos=2",
    picture: "./png/luna.webp",
    name: "Luna",
    breed: "Chien",
    isFemale: true,
    text:
      (<span>Si la Fondation ne l’avait pas sauvée, cette chienne aurait été condamnée à une vie d’exploitation, sans tendresse ni soin. Aujourd’hui, elle découvre la joie d’être entourée de bienveillance et adore les caresses qui lui ont tant manqué. Son rêve ? Trouver enfin un foyer où elle serait l’unique animal, prête à offrir tout son amour et sa fidélité à une famille. Cette boule de joie attend de rencontrer ceux qui lui offriront la vie douce et aimante qu’elle mérite.</span>),
    title: (<span>La stérilisation</span>),
    tips:
      (<span>Stériliser votre animal, c’est agir pour son bien-être et pour la communauté : Évitez les portées non désirées et réduisez l’abandon.\nAméliorez leur santé et offrez-leur une vie plus longue.\nCalmez les comportements indésirables avec moins d’agressivité et de fugues.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_5_a_10_ans&id=24255",
    picture: "./png/avocat.webp",
    name: "Avocat",
    breed: "Chat",
    isFemale: false,
    text:
      (<span>Avocat a connu un début de vie difficile, enfermé dans l’obscurité avec des dizaines d’autres chiens. Depuis son sauvetage, il a retrouvé la santé mais reste craintif, n’ayant jamais connu la chaleur d’un foyer. Pourtant, avec de la patience et de la douceur, il est prêt à offrir sa confiance et à devenir un compagnon loyal. Donnez-lui sa chance, et laissez-le découvrir le bonheur d’une vraie famille.</span>),
    title: (<span>Langage corporel chat</span>),
    tips:
      (<span>Comprendre le langage corporel d'un chat est crucial pour établir une relation de confiance et communiquer efficacement. Les signes comme la queue dressée, les oreilles droites ou les yeux plissés révèlent son état d'esprit. Cela permet d'anticiper ses besoins et d'éviter les malentendus.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&id=24483",
    picture: "./png/diesel.webp",
    name: "DIESEL",
    breed: "Chien",
    isFemale: false,
    text:
      (<span>Je suis Diesel ! Sauvé d’une vie difficile, je suis maintenant en pleine forme grâce à la Fondation qui m’a offert de bons repas et beaucoup d’attention. J’ai un cœur d’or et je suis très affectueux avec les humains, petits ou grands. Je m’entends généralement mieux avec les femelles qu’avec les mâles. J’ai tant d’amour à donner et je rêve d’un foyer avec un jardin où je peux jouer. Puis-je compter sur vous pour partager ma vie ?</span>),
    title: (<span>Les règles de bases chien</span>),
    tips: (<span>Avant d'accueillir un chien, veillez à pouvoir répondre à tous ses besoins : assurer les balades, toujours en laisse et harnais/collier, des jouets, des paniers, un terrain clos, des gamelles…</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_1_a_5_ans&id=27085",
    picture: "./png/jilian.webp",
    name: "JILIAN",
    breed: "Chat",
    isFemale: true,
    text:
      (<span>Jilian a été trouvée, avec sa mère et ses frères et sœurs, dans des conditions déplorables, entassée dans des boîtes. Heureusement, la Fondation a réussi à lui offrir un refuge temporaire, mais il est maintenant temps qu'elle trouve sa famille pour la vie. Ce petit chaton attend avec impatience d'être accueilli dans un foyer aimant où elle pourra s'épanouir et partager tout son amour. Offrir un chez-soi à Jilian, c'est lui donner une chance de vivre heureuse.</span>),
    title: (<span>Comportementaliste</span>),
    tips:
      (<span>Un spécialiste peut identifier les causes et vous conseiller pour rétablir une relation sereine avec votre chat par exemple si votre chat présente des troubles du comportement comme de l'agressivité, de l'anxiété ou des problèmes d'élimination. </span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?id=27989&sos=1",
    picture: "./png/monsieur.webp",
    name: "MONSIEUR",
    breed: "Chien",
    isFemale: false,
    text:
      (<span>Retrouvé abandonné en forêt, ce petit Fox a connu une histoire difficile. Avec sa bouille adorable et ses yeux pleins d'espoir, il est très affectueux avec les humains mais préfère être le seul animal de la maison. Il rêve d'une vie à la campagne, où il pourra courir librement et retrouver la joie de vivre. Offrir à ce petit chien un foyer aimant et paisible, c’est lui donner une seconde chance. Adoptez-le et laissez-le apporter tendresse et fidélité dans votre vie.</span>),
    title: (<span>La socialisation</span>),
    tips:
      (<span>La socialisation précoce est cruciale pour le développement harmonieux d'un chien. Exposer votre chien à différents environnements, personnes et animaux dans ses premiers mois l'aide à se construire une personnalité équilibrée et à devenir un compagnon sociable.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&s=&id=24221",
    picture: "./png/prajna.webp",
    name: "PRAJNA",
    breed: "Chien",
    isFemale: false,
    text: (
      <span>
        Ce chien courageux a été sauvé d’une situation terrible, détenu avec des dizaines d’autres chiens, sans soin ni nourriture. Aujourd’hui en pleine forme, il rêve de découvrir la chaleur d’un foyer. Un peu timide et indépendant, il s’épanouira au contact d’un autre chien qui saura le rassurer. Une fois en confiance, il devient un compagnon actif et joueur, prêt à vivre une nouvelle vie pleine de bonheur.
      </span>
    ),
    title: (
      <span>
        Les soins <br />
        quotidiens
      </span>
    ),
    tips: (
      <span>
        Pour le bien-être de votre chien, veilliez à lui apporter des soins quoitidiens : assurer les promenades, vérifier ses dents, ses poils, ses yeux, ses oreilles et lui apporter une alimentation adaptée et de qualité
      </span>
    ),
  },

  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?&id=22675&sos=2",
    picture: "./png/sophie.webp",
    name: "SOPHIE",
    breed: "Chat",
    isFemale: true,
    text:
      (<span>Sophie est une jolie petite mamie chatte qui cherche une nouvelle famille. Arrivée au refuge après que son propriétaire âgé ne pouvait plus s'occuper d'elle, elle craint que son âge ne fasse peur aux adoptants. Pourtant, après avoir connu la chaleur d’un foyer, elle rêve de passer ses dernières années choyée et entourée d’amour. Qui saura lui offrir cette chance et lui redonner le bonheur d’un véritable foyer ?</span>),
    title: (<span>La médecine douce</span>),
    tips:
      (<span>Saviez-vous que la médecine douce, comme l'homéopathie ou la phytothérapie, offre une approche complémentaire à la médecine conventionnelle pour les animaux. Elle se concentre sur le traitement global de l'animal, en prenant en compte son bien-être physique et émotionnel. </span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?&id=7673&sos=1",
    picture: "./png/gaelle.webp",
    name: "GAELLE",
    breed: "Chien",
    isFemale: true,
    text:
      (<span>Gaëlle est une chienne adorable, affectueuse et en quête de contact, mais elle attend désespérément une famille depuis de nombreuses années. Elle recherche un foyer sans jeunes enfants ni autres animaux. Gaëlle adore jouer à l'extérieur et se promener, alors un jardin et de belles balades sont essentiels pour son bonheur. Bien qu'elle ait besoin d'un peu d'éducation, elle promet d'être une compagne attentive et pleine d'amour. Qui lui offrira la chance de commencer une nouvelle vie ?</span>),
    title: (<span>L'éducation</span>),
    tips:
      (<span>L'éducation d'un chien est primordiale pour son épanouissement et votre relation. Elle lui apprend la sociabilité, la discipline et la confiance, le rendant plus équilibré et agréable au quotidien. Une éducation bienveillante favorise ainsi le bien-être de l'animal et facilite son intégration.</span>),
  },

  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&id=26281",
    picture: "./png/olly.webp",
    name: "OLLY",
    breed: "Chien",
    isFemale: true,
    text:
      (<span>Olly a vécu avec de nombreux autres animaux, mais son maître n’a pas pu s’en occuper. Elle est très gentille et adore les câlins, mais elle est aussi dynamique et aime jouer, courir et se promener. Elle ne pourra pas être heureuse sans jardin, car elle a besoin d’espace pour s’épanouir. Olly est propre et peut être éduquée pour devenir une chienne bien dans sa patte. Elle s’entend bien avec les autres chiens et est compatible avec les chats. Prêt à lui faire une place dans votre vie ?</span>),
    title: (<span>L'hydratation</span>),
    tips:
      (<span>L'hydratation est cruciale pour la santé et le bien-être du chien. Surtout par temps chaud ou avec une activité physique intense, il doit boire suffisamment pour compenser les pertes en eau. Adaptez les quantités à sa taille, son âge et son mode de vie.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_0_a_3_ans&id=29712",
    picture: "./png/kiara.webp",
    name: "KIARA",
    breed: "Chien",
    isFemale: true,
    text:
      (<span>KIARA, une chienne dynamique et sociable, cherche sa famille idéale. Abandonnée sur un balcon, elle a trouvé refuge et rêve maintenant d'un foyer avec jardin.
        Très propre et sportive, elle s'entend bien avec les autres chiens mais pas avec les chats. Elle recherche des propriétaires qui pourront compléter son éducation et l'emmener en balade.
        KIARA n'attend plus que vous pour écrire le prochain chapitre de sa vie !</span>),
    title: (<span>La mastication</span>),
    tips:
      (<span>Saviez-vous que la mastication est essentielle pour la santé bucco-dentaire et le bien-être du chien. Mâcher stimule ses mâchoires, nettoie ses dents et évite le tartre. Cela satisfait aussi son besoin naturel de broyer des aliments. </span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_0_a_12_mois&id=29721",
    picture: "./png/venise.webp",
    name: "VENISE",
    breed: "Chat",
    isFemale: true,
    text:
      (<span>Venise a été trouvée errante, toute petite, mais elle a progressivement appris à faire confiance aux humains. Elle aspire maintenant à continuer son chemin entourée de tendresse et de sécurité. Cette petite chérie attend une famille prête à lui offrir l’amour et l’attention qu’elle mérite. Offrez-lui la chance de vivre heureuse et épanouie à vos côtés.</span>),
    title: (<span>Parasites</span>),
    tips:
      (<span>Vermifuger et traiter son chat contre les puces est crucial pour sa santé et son bien-être. Ces traitements préventifs protègent votre chat des affections liées aux parasites et maintiennent son équilibre physique. Ils contribuent ainsi à la qualité de vie de votre compagnon.</span>),
  },

  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_5_a_10_ans&id=23893",
    picture: "./png/popaye.webp",
    name: "Popaye",
    breed: "Chat",
    isFemale: false,
    text:
      (<span>Popaye est un beau chat noir et blanc qui a été sauvé d'une vie difficile, vivant dans un logement insalubre avec de nombreux autres félins. Grâce à la Fondation, il a été soigné et choyé, et il se prépare maintenant à une nouvelle vie. Il espère trouver un foyer chaleureux où il pourra vivre dignement, entouré d'amour et de câlins quotidiens. Popaye attend sa famille… Serez-vous celle qui lui donnera sa chance ?</span>),
    title: (<span>Les soins quotidiens</span>),
    tips:
      (<span>Les soins quotidiens sont essentiels pour la santé et le bien-être du chat. Brossez-le régulièrement, vérifiez ses yeux, oreilles et griffes. Nettoyez sa litière chaque jour. Jouez et interagissez avec lui pour stimuler son activité physique et mentale.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_0_a_3_ans&id=26847",
    picture: "./png/diamant.webp",
    name: "Diamant",
    breed: "Chien",
    isFemale: false,
    text:
      (<span>Diamant a vécu avec de nombreux chiens dans des conditions difficiles, mais au refuge, il découvre qu'une autre vie est possible. Bien qu'il soit craintif et nécessite de la patience pour s'apprivoiser, il mérite une chance de connaître l'amour et la sécurité. Qui lui offrira cette opportunité de s'épanouir et de trouver enfin un foyer chaleureux ?</span>),
    title: (<span>Langage corporel chien</span>),
    tips:
      (<span>Connaître le langage corporel du chien est essentiel pour interpréter ses émotions. La queue haute et remuante montre son excitation, les oreilles basses sa soumission, et le grognement sa méfiance. Comprendre ces signaux aide à interagir avec lui en toute sécurité et à créer une relation de confiance.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?a=de_1_a_5_ans&id=22696",
    picture: "./png/tac.webp",
    name: "TAC",
    breed: "Chat",
    isFemale: false,
    text:
      (<span>Vous rêvez d’un adorable chat gris et blanc ? Tac est là pour vous ! Gentil et câlin, il recherche un foyer où il pourra recevoir de l’amour et des caresses, ainsi que de bonnes croquettes. Un peu craintif au départ, il a besoin d’être apprivoisé avec douceur. Une fois en confiance, il deviendra un compagnon affectueux. Tac se sentirait mieux avec un ou deux chats amicaux dans un environnement calme, sans chiens, que ce soit en appartement ou en maison avec jardin. Offrez-lui la chance d’une vie heureuse !</span>),
    title: (<span>Adoption deuxième chat</span>),
    tips:
      (<span>Accueillir un second chat nécessite de respecter certaines règles pour une intégration réussie. Il faut d'abord s'assurer que votre premier chat est stable et sociable. Présentez-les progressivement et calmement, sans les forcer à interagir. Soyez patient, leur cohabitation se fera à leur rythme.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&id=22487",
    picture: "./png/louxor.webp",
    name: "LOUXOR",
    breed: "Chien",
    isFemale: false,
    text:
      (<span>Louxor est un beau chien qui a vécu dans des conditions difficiles, entouré de trop d’autres animaux. Au refuge, il a découvert le confort, mais il lui manque encore une chose essentielle : une famille. Dynamique et affectueux, il a besoin d’une maison avec un jardin et de belles balades. Louxor adore interagir avec les humains et se réjouit de rencontrer des copains chiens. Il nécessitera un peu d’éducation pour canaliser son énergie. Prêt à vous amuser avec lui ?</span>),
    title: (<span>Vaccins</span>),
    tips:
      (<span>Le vaccin est essentiel pour protéger votre chien contre les maladies graves et potentiellement mortelles. Il renforce son système immunitaire, prévient des épidémies, et permet de voyager en sécurité. C'est une mesure de prévention efficace pour garantir la santé et le bien-être de votre chien.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?&id=10018&sos=2",
    picture: "./png/boubou.webp",
    name: "BOUBOU",
    breed: "Chat",
    isFemale: false,
    text:
      (<span>On m'a trouvé blessé le long de la route et ramené à la Mare Auzou. Je suis FIV+, mais si je suis le seul animal de la maison je peux encore couler des jours heureux auprès d'une personne qui m'acceptera et m'aimera. Je suis gentil, aime les câlins. Si vous vous sentez seul, nous pourrions faire un bout de chemin ensemble.</span>),
    title: (<span>Habitation</span>),
    tips:
      (<span>Rendre un chat heureux passe par la satisfaction de ses besoins essentiels. Assurez-lui un environnement sûr et confortable, avec des zones de couchage, de jeu et de grattage. Stimulez son instinct naturel par des jouets et des activités adaptées. </span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?&id=10048&sos=1",
    picture: "./png/brasco.webp",
    name: "BRASCO",
    breed: "Chien",
    isFemale: false,
    text:
      (<span>Brasco a été retrouvé errant après avoir été abandonné par son maître. Bien qu’il ait été un chien très actif, il est maintenant un papy chien calme et en quête d’amour. Affectueux et obéissant, il aspire à savourer ses dernières années dans un foyer chaleureux et douillet. Si vous cherchez un compagnon tendre et loyal, Brasco pourrait bien être celui qui comblera votre cœur. Offrez-lui la chance de connaître la tendresse qu’il mérite !</span>),
    title: (<span>L'alimentation</span>),
    tips:
      (<span>Une alimentation adaptée est essentielle à la santé et au bien-être du chien. Choisissez des croquettes ou aliments frais de qualité, équilibrés en nutriments. Respectez les besoins spécifiques de votre chien selon son âge, son activité et son état de santé.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chat_a_adopter.php?&id=12637&sos=2",
    picture: "./png/isis.webp",
    name: "ISIS",
    breed: "Chat",
    isFemale: true,
    text:
      (<span>Isis est un adorable matouchat qui adore les caresses et attend un foyer aimant. Arrivée au refuge alors qu'elle n'était qu'un bébé, elle a maintenant passé plusieurs années à espérer une famille. Ses yeux coulants peuvent effrayer certains visiteurs, mais ce n'est rien de grave. Les bénévoles du refuge affirment qu'un foyer calme et attentionné ferait toute la différence pour elle. \n\nIsis est un peu timide au début, mais elle se transformera en un véritable amour une fois en confiance. Offrez-lui la chance de commencer une nouvelle vie pleine de tendresse. Pensez à Isis, elle a besoin de vous !</span>),
    title: (<span>Vaccins</span>),
    tips:
      (<span>Vacciner son chat protège contre des maladies graves et mortelles en stimulant son système immunitaire. Même les chats d'intérieur peuvent être exposés aux agents pathogènes, la vaccination est donc recommandée pour tous les chats.</span>),
  },
  {
    link: "https://adoption.fondationbrigittebardot.fr/chien_a_adopter.php?a=de_4_a_9_ans&s=&id=24221",
    picture: "./png/prajna.webp",
    name: "PRAJNA",
    breed: "Chat",
    isFemale: false,
    text: (
      <span>
        Ce chien courageux a été sauvé d’une situation terrible, détenu avec des dizaines d’autres chiens, sans soin ni nourriture. Aujourd’hui en pleine forme, il rêve de découvrir la chaleur d’un foyer. Un peu timide et indépendant, il s’épanouira au contact d’un autre chien qui saura le rassurer. Une fois en confiance, il devient un compagnon actif et joueur, prêt à vivre une nouvelle vie pleine de bonheur.
      </span>
    ),
    title: (
      <span>
        Les soins <br />
        quotidiens
      </span>
    ),
    tips: (
      <span>
        Pour le bien-être de votre chien, veilliez à lui apporter des soins quoitidiens : assurer les promenades, vérifier ses dents, ses poils, ses yeux, ses oreilles et lui apporter une alimentation adaptée et de qualité
      </span>
    ),
  },

];

export default animalsInfos;
