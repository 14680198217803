const fragmentShader = `

uniform float uTime;
varying vec2 vUv;
uniform float uValue1;
uniform float uValue2;
uniform float uValue3;
uniform float uValue4;
uniform float uTimeFactor1;
uniform float uTimeFactor2;
uniform float uTimeFactor3;
uniform float uTimeFactor4;
uniform vec2 uFactor1;
uniform vec2 uFactor2;
uniform vec2 uFactor3;
uniform vec2 uFactor4;
uniform float uScale;
uniform float uProgress;

// Snow function
vec3 snow(float size, mat3 p, vec2 uv) {
    float f = size * 10.0;
    vec2 q = uv * (1.0 + f * 0.5);
    q += vec2(q.y * (0.3 * mod(f * 7.37568, 1.) - 0.3 * .5), 0.6 * uTime * 0.5 / (1. + f * 0.5 * .03));
    vec3 n = vec3(floor(q), 31.189 + f);
    vec3 m = floor(n) * .00001 + fract(n);
    vec3 mp = (31415.9 + m) / fract(p * m);
    vec3 r = fract(mp);
    float y = 0.015 / distance(mod(q, 1.) - .5 + .9 * r.xy, vec2(0.5, 0.5));
    y = clamp(y, 0.5, 1.5) - 0.5;

    return vec3(y);
}

void main() {
    // Mirror uvs for the reflection in the lake and a seamless texture on the x axis
    vec2 uvMirroredY = vec2(vUv.x, abs(vUv.y - 0.5) * 2.0);
    vec2 uvMirrored = vec2(1.0 - abs(vUv.x - 0.5) * 2.0, abs(vUv.y - 0.5) * 2.0);

    // Northern light effects
    vec2 distortion = 2.0 * uvMirrored - 1.0;
    distortion = vec2(distortion.x * mix(0.125, 0.5, 1.0), distortion.y * 1.0);
    distortion += 0.1 * cos(uScale * uValue1 * distortion.yx + uTimeFactor1 * uTime * 0.5 + uFactor1);
    distortion += 0.1 * cos(uScale * uValue2 * distortion.yx + uTimeFactor2 * uTime * 0.5 + uFactor2);
    distortion += 0.3 * cos(uScale * uValue3 * distortion.yx + uTimeFactor3 * uTime * 0.5 + uFactor3);
    distortion += 0.3 * cos(uScale * uValue4 * distortion.yx + uTimeFactor4 * uTime * 0.5 + uFactor4);
    vec2 uvDistorted = vec2(mix(uvMirrored.x, length(distortion), uProgress), mix(uvMirrored.y, 0.5 * length(distortion) + 0.15, uProgress));
    float northernLights = uvDistorted.x * uvDistorted.y;

    // Add multiple layers of snow
    mat3 p = mat3(13.323122, 23.5112, 21.71123, 21.1212, 28.7312, 11.9312, 21.8112, 14.7212, 61.3934);
    vec3 acc = vec3(0.0);
   acc += snow(10.0, p, uvMirroredY)
    + snow(15.0, p, uvMirroredY)
    + snow(20.0, p, uvMirroredY);

    // Colors of the horizon and the lake
    vec3 lightBlue = vec3(120.0 / 255.0, 150.0 / 255.0, 200.0 / 255.0); // Couleur bleu clair plus foncé
    vec3 darkBlue = vec3(5.0 / 255.0, 5.0 / 255.0, 80.0 / 255.0); // Couleur bleu foncé

    // Horizon gradient and darkened lake reflection effect
    float horizonGradient = clamp(0.05 - abs(((vUv.y) - 0.5) * 2.0), 0.0, 0.05) * 20.0;
    float lake = clamp(1.0 - step(vUv.y, 0.5), 0.0, 10.0);

    // Final color
    vec3 color = vec3((-(sin(uTime * 0.25) * 0.25 + 0.9) + uvMirrored.x) * 0.1 + 0.1, (-(cos(uTime * 0.25) * 0.25 + 0.5) + uvMirrored.y) * 0.5 + 0.5, 0.5);
    color *= vec3(0.4);
    color += mix(vec3(0.4), vec3(+0.005), lake);
    color = mix(mix(darkBlue * 0.9, color, 0.15), mix(darkBlue, color, 0.1), lake);

    vec3 northernLightColor = vec3(northernLights * 0.0, northernLights * 0.4, northernLights * 0.2);
    color += mix(northernLightColor * 0.4, northernLightColor, lake);
    color = mix(clamp(color, 0.0, 1.0), lightBlue, clamp(horizonGradient * horizonGradient * horizonGradient, 0.0, 1.0));
    color = mix(clamp(color, 0.0, 1.0), lightBlue, acc);
    color = clamp(color, 0.0, 1.0);
  
    gl_FragColor = vec4(color, 1.0);
}
`;

export default fragmentShader;