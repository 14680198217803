import { useState } from "react";

export default function Info() {
  const [show, setShow] = useState(false);

  return (
    <>
      <button
        onClick={() => setShow(true)}
        className="absolute top-4 lg:top-auto lg:bottom-8 right-4 lg:right-10 2xl:right-14 w-16 lg:w-24 z-30"
      >
        <svg width="100%" viewBox="0 0 225 72" fill="none">
          <path
            d="M222.808 50.1796C236.812 27.2205 180.295 11.1303 150.287 5.9551C120.278 0.779897 90.2689 -1.25882 66.5119 0.779896C42.755 2.81861 -3.75873 12.3535 0.242455 34.1835C4.24363 56.0135 69.4295 67.7439 101.522 70.8804C136.116 73.5464 208.804 73.1387 222.808 50.1796Z"
            fill="#D8D8FF"
          />
          <path
            d="M84.4194 25.876C84.6994 25.82 85.0167 25.7873 85.3714 25.778C85.7354 25.7593 86.0387 25.75 86.2814 25.75C86.5521 25.75 86.8554 25.7593 87.1914 25.778C87.5367 25.7873 87.8634 25.82 88.1714 25.876V45C87.8634 45.056 87.5367 45.0887 87.1914 45.098C86.8554 45.1167 86.5521 45.126 86.2814 45.126C86.0387 45.126 85.7354 45.1167 85.3714 45.098C85.0167 45.0887 84.6994 45.056 84.4194 45V25.876Z"
            fill="#003AA5"
          />
          <path
            d="M92.8806 25.918C93.1793 25.862 93.5573 25.82 94.0146 25.792C94.4813 25.764 94.8453 25.75 95.1066 25.75C95.368 25.75 95.704 25.7593 96.1146 25.778C96.5253 25.7873 96.922 25.82 97.3046 25.876L96.3526 30.174L96.0866 30.258C96.1426 30.986 96.1893 31.8027 96.2266 32.708C96.2733 33.604 96.3106 34.5093 96.3386 35.424C96.3666 36.3293 96.3806 37.1507 96.3806 37.888V45C96.0726 45.056 95.7693 45.0887 95.4706 45.098C95.172 45.1167 94.9013 45.126 94.6586 45.126C94.4346 45.126 94.1546 45.1167 93.8186 45.098C93.492 45.0887 93.1793 45.056 92.8806 45V25.918ZM97.8646 34.15C97.538 33.4407 97.23 32.75 96.9406 32.078C96.6606 31.406 96.4086 30.7853 96.1846 30.216L94.4346 28.172L97.3046 25.876L102.401 36.712C102.755 37.4867 103.087 38.238 103.395 38.966C103.703 39.6847 103.973 40.3193 104.207 40.87L106.097 42.886L103.031 45L97.8646 34.15ZM107.497 25.89V44.958C107.301 44.9953 107.058 45.028 106.769 45.056C106.489 45.084 106.209 45.1027 105.929 45.112C105.658 45.1213 105.429 45.126 105.243 45.126C104.953 45.126 104.613 45.1167 104.221 45.098C103.829 45.0887 103.432 45.056 103.031 45L104.025 40.898L104.319 40.828C104.253 40.016 104.197 39.1387 104.151 38.196C104.104 37.2533 104.071 36.3387 104.053 35.452C104.034 34.5653 104.025 33.8 104.025 33.156V28.018C104.025 27.1873 104.183 26.5993 104.501 26.254C104.827 25.8993 105.359 25.722 106.097 25.722H107.301L107.497 25.89Z"
            fill="#003AA5"
          />
          <path
            d="M115.924 45C115.616 45.056 115.299 45.0887 114.972 45.098C114.655 45.1167 114.356 45.126 114.076 45.126C113.805 45.126 113.493 45.1167 113.138 45.098C112.793 45.0887 112.466 45.056 112.158 45V26.044L112.354 25.876H115.896V27.276C115.896 28.0227 115.901 28.7833 115.91 29.558C115.919 30.3233 115.924 31.0653 115.924 31.784V45ZM123.89 25.876C123.955 26.0813 124.002 26.3193 124.03 26.59C124.058 26.8607 124.072 27.108 124.072 27.332C124.072 27.948 123.899 28.3913 123.554 28.662C123.218 28.9327 122.709 29.068 122.028 29.068H118.22C117.492 29.068 116.815 29.0633 116.19 29.054C115.565 29.0447 114.963 29.0307 114.384 29.012V25.876H123.89ZM122.294 33.884C122.359 34.108 122.406 34.3693 122.434 34.668C122.471 34.9667 122.49 35.2187 122.49 35.424C122.49 35.6387 122.471 35.8953 122.434 36.194C122.406 36.4833 122.359 36.7447 122.294 36.978H116.764C116.391 36.978 115.994 36.9733 115.574 36.964C115.163 36.9453 114.804 36.936 114.496 36.936V33.926C114.804 33.9167 115.163 33.9073 115.574 33.898C115.994 33.8887 116.391 33.884 116.764 33.884H122.294Z"
            fill="#003AA5"
          />
          <path
            d="M129.56 35.424C129.56 36.2173 129.611 37.0247 129.714 37.846C129.816 38.6673 130.012 39.428 130.302 40.128C130.591 40.828 131.006 41.3927 131.548 41.822C132.098 42.2513 132.817 42.466 133.704 42.466C134.6 42.466 135.318 42.2513 135.86 41.822C136.401 41.3927 136.816 40.828 137.106 40.128C137.395 39.428 137.591 38.6673 137.694 37.846C137.796 37.0247 137.848 36.2173 137.848 35.424C137.848 34.6307 137.796 33.828 137.694 33.016C137.591 32.1947 137.395 31.4387 137.106 30.748C136.816 30.048 136.401 29.4833 135.86 29.054C135.318 28.6247 134.6 28.41 133.704 28.41C132.817 28.41 132.098 28.6247 131.548 29.054C131.006 29.4833 130.591 30.048 130.302 30.748C130.012 31.4387 129.816 32.1947 129.714 33.016C129.611 33.828 129.56 34.6307 129.56 35.424ZM125.626 35.424C125.626 33.4827 125.915 31.7653 126.494 30.272C127.082 28.7787 127.973 27.612 129.168 26.772C130.362 25.9227 131.874 25.498 133.704 25.498C135.085 25.498 136.28 25.7407 137.288 26.226C138.305 26.7113 139.145 27.3973 139.808 28.284C140.48 29.1707 140.979 30.2207 141.306 31.434C141.632 32.6473 141.796 33.9773 141.796 35.424C141.796 37.3747 141.502 39.1013 140.914 40.604C140.326 42.0973 139.434 43.2687 138.24 44.118C137.045 44.958 135.533 45.378 133.704 45.378C132.322 45.378 131.123 45.1353 130.106 44.65C129.098 44.1647 128.262 43.4787 127.6 42.592C126.937 41.696 126.442 40.6413 126.116 39.428C125.789 38.2147 125.626 36.88 125.626 35.424Z"
            fill="#003AA5"
          />
        </svg>
      </button>
      <section
        onPointerDown={() => setShow(false)}
        className={`
          ${show ? "opacity-100" : "opacity-0 pointer-events-none"
          } transition-opacity duration-500
          p-2 lg:p-10 2xl:p-14 z-50 fixed w-screen top-0 left-0
          h-svh backdrop-blur-md flex flex-col items-center gap-4 justify-center`}
      >
        <div className="pointer-events-none blob3 bg-[#D8D8FF] p-2 w-full h-full aspect-square lg:w-auto lg:h-full flex flex-col justify-center items-center gap-12 p-4 lg:p-20 text-center text-[#003aa5]">


          <h2 className="text-xl lg:text-4xl mt-[35px] lg:mt-0">
            Le Calendrier de l'Avent
            <br />
            de la Fondation Brigitte Bardot
          </h2>
          <p className="text-xs lg:text-base text-[#003aa5] mt-[-25px] lg:mt-0">
            Plongez dans la magie de Noël tout en soutenant une cause qui nous tient à cœur. Chaque jour, ouvrez une case de notre calendrier de l'Avent interactif et laissez-vous toucher par l'histoire unique d'un animal à adopter. Derrière chaque fenêtre se cache une vie à sauver, un compagnon à aimer, et une opportunité de faire une différence.
            <br /><br />
            Cet univers intéractif vous invite à parcourir ces récits émouvants et à rencontrer nos adorables protégés en quête d'un foyer chaleureux. Ensemble, transformons ce Noël en une saison de solidarité et d'espoir pour nos amis les animaux. 🌟
          </p>
        </div>
        <div className="drop-shadow-md rounded-full w-12 absolute top-12 right-9 lg:top-28 lg:w-40 lg:right-24 2xl:right-44">
          <img
            src="./png/candyCane.webp"
            alt="candy-cane"
            className="object-contain h-full w-full object-center drop-shadow-md"
          />
        </div>
        <button
          className="w-16 h-16 mt-6  z-10 pointer-events-auto"
          onClick={() => setShow(false)}
        >
          <Exit />
        </button>
      </section>
    </>
  );
}

function Exit() {
  return (
    <svg
      width="100%"
      viewBox="0 0 129 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M125.952 34.6561C114.477 2.15409 75.5822 -1.0624 57.5694 1.39209C39.5565 3.84659 24.0011 10.177 14.5483 19.7025C5.09555 29.228 -9.06868 54.7282 9.89647 80.5249C28.8616 106.322 68.3726 101.594 85.7574 96.0055C103.937 89.0982 137.427 67.158 125.952 34.6561Z"
        fill="#D8D8FF"
        className="drop-shadow-md"
      />
      <path
        d="M24.2847 46.1814L29.5347 46.1254C29.628 46.4894 29.6747 46.8721 29.6747 47.2734C29.6747 47.4694 29.6607 47.6701 29.6327 47.8754C29.614 48.0714 29.5814 48.2628 29.5347 48.4494L24.2567 48.3794L20.3507 48.4494C20.304 48.2628 20.2667 48.0714 20.2387 47.8754C20.22 47.6701 20.2107 47.4694 20.2107 47.2734C20.2107 47.0774 20.22 46.8861 20.2387 46.6994C20.2667 46.5034 20.304 46.3121 20.3507 46.1254L24.2847 46.1814ZM22.7587 47.5114C22.7027 46.8581 22.6327 46.2188 22.5487 45.5934C22.4647 44.9588 22.4227 44.3474 22.4227 43.7594C22.4227 42.9101 22.6 42.1494 22.9547 41.4774C23.3094 40.8054 23.818 40.2734 24.4807 39.8814C25.1527 39.4894 25.9507 39.2934 26.8747 39.2934C27.556 39.2934 28.2327 39.3634 28.9047 39.5034C29.586 39.6434 30.146 39.8021 30.5847 39.9794C30.594 40.3434 30.5334 40.7308 30.4027 41.1414C30.272 41.5521 30.0947 41.8741 29.8707 42.1074C29.5254 42.0048 29.1427 41.9114 28.7227 41.8274C28.3027 41.7434 27.8967 41.7014 27.5047 41.7014C26.8607 41.7014 26.3567 41.8974 25.9927 42.2894C25.638 42.6721 25.4607 43.2274 25.4607 43.9554C25.4607 44.5061 25.5074 45.0941 25.6007 45.7194C25.7034 46.3354 25.792 46.9468 25.8667 47.5534V59.9994C25.6334 60.0368 25.3767 60.0648 25.0967 60.0834C24.826 60.1021 24.5554 60.1114 24.2847 60.1114C24.042 60.1114 23.7854 60.1021 23.5147 60.0834C23.244 60.0648 22.992 60.0368 22.7587 59.9994V47.5114Z"
        fill="#003AA5"
      />
      <path
        d="M32.4616 54.0914V51.8094H38.9576V51.4454C38.9576 50.8388 38.8689 50.2834 38.6916 49.7794C38.5236 49.2754 38.2482 48.8694 37.8656 48.5614C37.4922 48.2534 36.9929 48.0994 36.3676 48.0994C35.4716 48.0994 34.8042 48.4774 34.3656 49.2334C33.9362 49.9894 33.7216 51.0441 33.7216 52.3974V53.3774C33.7216 54.1988 33.8056 54.9501 33.9736 55.6314C34.1416 56.3034 34.4449 56.8354 34.8836 57.2274C35.3316 57.6194 35.9709 57.8154 36.8016 57.8154C37.5016 57.8154 38.1736 57.7174 38.8176 57.5214C39.4616 57.3254 40.0729 57.0828 40.6516 56.7934C40.9129 57.0361 41.1276 57.3488 41.2956 57.7314C41.4636 58.1141 41.5709 58.4688 41.6176 58.7954C40.9456 59.2808 40.1382 59.6634 39.1956 59.9434C38.2529 60.2234 37.2916 60.3634 36.3116 60.3634C35.1822 60.3634 34.2396 60.1674 33.4836 59.7754C32.7276 59.3741 32.1256 58.8328 31.6776 58.1514C31.2389 57.4701 30.9262 56.6954 30.7396 55.8274C30.5529 54.9594 30.4596 54.0541 30.4596 53.1114C30.4596 51.7208 30.6742 50.4748 31.1036 49.3734C31.5329 48.2628 32.1862 47.3854 33.0636 46.7414C33.9409 46.0974 35.0469 45.7754 36.3816 45.7754C37.5949 45.7754 38.6169 46.0414 39.4476 46.5734C40.2876 47.0961 40.9222 47.8101 41.3516 48.7154C41.7809 49.6114 41.9956 50.6148 41.9956 51.7254C41.9956 52.2108 41.9769 52.6261 41.9396 52.9714C41.9022 53.3074 41.8369 53.6808 41.7436 54.0914H32.4616Z"
        fill="#003AA5"
      />
      <path
        d="M53.6302 46.1254C53.6302 46.5361 53.5649 47.0308 53.4342 47.6094C53.3129 48.1788 53.1169 48.6828 52.8462 49.1214C52.6502 49.0654 52.4496 49.0281 52.2442 49.0094C52.0482 48.9814 51.8522 48.9674 51.6562 48.9674C51.1522 48.9674 50.7089 49.0701 50.3262 49.2754C49.9529 49.4714 49.6309 49.7374 49.3602 50.0734C49.0896 50.4001 48.8562 50.7734 48.6602 51.1934C48.4642 51.6041 48.2962 52.0194 48.1562 52.4394L47.7222 51.8794C47.7409 51.1981 47.8295 50.5121 47.9882 49.8214C48.1469 49.1214 48.3896 48.4821 48.7162 47.9034C49.0522 47.3154 49.4909 46.8441 50.0322 46.4894C50.5829 46.1348 51.2596 45.9574 52.0622 45.9574C52.3516 45.9574 52.6176 45.9714 52.8602 45.9994C53.1029 46.0274 53.3596 46.0694 53.6302 46.1254ZM44.5582 47.6234C44.6236 47.3341 44.7636 47.0261 44.9782 46.6994C45.2022 46.3634 45.4309 46.1114 45.6642 45.9434C46.6722 46.1208 47.4236 46.5921 47.9182 47.3574C48.4129 48.1134 48.6976 49.0748 48.7722 50.2414V59.9994C48.5669 60.0368 48.3242 60.0648 48.0442 60.0834C47.7642 60.1021 47.4796 60.1114 47.1902 60.1114C46.9102 60.1114 46.6302 60.1021 46.3502 60.0834C46.0702 60.0648 45.8182 60.0368 45.5942 59.9994V50.5074C45.5942 49.7421 45.4776 49.1214 45.2442 48.6454C45.0202 48.1694 44.7916 47.8288 44.5582 47.6234Z"
        fill="#003AA5"
      />
      <path
        d="M57.1869 46.0134C57.8402 46.0134 58.3069 46.2188 58.5869 46.6294C58.8669 47.0308 59.0069 47.5861 59.0069 48.2954V59.9994C58.7922 60.0368 58.5449 60.0648 58.2649 60.0834C57.9849 60.1021 57.7049 60.1114 57.4249 60.1114C57.1449 60.1114 56.8649 60.1021 56.5849 60.0834C56.3049 60.0648 56.0529 60.0368 55.8289 59.9994V46.2094L56.0109 46.0134H57.1869ZM63.4029 51.2914C63.4029 50.3581 63.2629 49.7048 62.9829 49.3314C62.7029 48.9581 62.3109 48.7714 61.8069 48.7714C61.3589 48.7714 60.8782 48.9208 60.3649 49.2194C59.8515 49.5088 59.3755 49.8961 58.9369 50.3814C58.5075 50.8574 58.1762 51.3801 57.9429 51.9494V48.9254C58.3629 48.3654 58.8342 47.8568 59.3569 47.3994C59.8795 46.9421 60.4442 46.5781 61.0509 46.3074C61.6575 46.0368 62.2782 45.9014 62.9129 45.9014C63.7249 45.9014 64.4015 46.0928 64.9429 46.4754C65.4935 46.8581 65.9042 47.3808 66.1749 48.0434C66.4549 48.6968 66.5949 49.4341 66.5949 50.2554V59.9994C66.3709 60.0368 66.1189 60.0648 65.8389 60.0834C65.5589 60.1021 65.2789 60.1114 64.9989 60.1114C64.7189 60.1114 64.4389 60.1021 64.1589 60.0834C63.8789 60.0648 63.6269 60.0368 63.4029 59.9994V51.2914ZM70.9629 51.2634C70.9629 50.3301 70.8182 49.6814 70.5289 49.3174C70.2489 48.9534 69.8615 48.7714 69.3669 48.7714C68.9375 48.7714 68.4709 48.9161 67.9669 49.2054C67.4722 49.4854 67.0009 49.8728 66.5529 50.3674C66.1049 50.8621 65.7315 51.4128 65.4329 52.0194V48.6454C65.8902 48.1414 66.3942 47.6841 66.9449 47.2734C67.4955 46.8534 68.0742 46.5221 68.6809 46.2794C69.2969 46.0274 69.9175 45.9014 70.5429 45.9014C71.3549 45.9014 72.0269 46.0974 72.5589 46.4894C73.1002 46.8721 73.5015 47.3948 73.7629 48.0574C74.0242 48.7201 74.1549 49.4668 74.1549 50.2974V56.9334C74.1549 57.4281 74.1922 57.8994 74.2669 58.3474C74.3415 58.7954 74.4722 59.1828 74.6589 59.5094C74.4349 59.7054 74.1455 59.8688 73.7909 59.9994C73.4362 60.1301 73.0629 60.1954 72.6709 60.1954C72.0455 60.1954 71.6022 60.0274 71.3409 59.6914C71.0889 59.3461 70.9629 58.8421 70.9629 58.1794V51.2634Z"
        fill="#003AA5"
      />
      <path
        d="M79.3014 54.0914V51.8094H85.7974V51.4454C85.7974 50.8388 85.7087 50.2834 85.5314 49.7794C85.3634 49.2754 85.0881 48.8694 84.7054 48.5614C84.3321 48.2534 83.8327 48.0994 83.2074 48.0994C82.3114 48.0994 81.6441 48.4774 81.2054 49.2334C80.7761 49.9894 80.5614 51.0441 80.5614 52.3974V53.3774C80.5614 54.1988 80.6454 54.9501 80.8134 55.6314C80.9814 56.3034 81.2847 56.8354 81.7234 57.2274C82.1714 57.6194 82.8107 57.8154 83.6414 57.8154C84.3414 57.8154 85.0134 57.7174 85.6574 57.5214C86.3014 57.3254 86.9127 57.0828 87.4914 56.7934C87.7527 57.0361 87.9674 57.3488 88.1354 57.7314C88.3034 58.1141 88.4107 58.4688 88.4574 58.7954C87.7854 59.2808 86.9781 59.6634 86.0354 59.9434C85.0927 60.2234 84.1314 60.3634 83.1514 60.3634C82.0221 60.3634 81.0794 60.1674 80.3234 59.7754C79.5674 59.3741 78.9654 58.8328 78.5174 58.1514C78.0787 57.4701 77.7661 56.6954 77.5794 55.8274C77.3927 54.9594 77.2994 54.0541 77.2994 53.1114C77.2994 51.7208 77.5141 50.4748 77.9434 49.3734C78.3727 48.2628 79.0261 47.3854 79.9034 46.7414C80.7807 46.0974 81.8867 45.7754 83.2214 45.7754C84.4347 45.7754 85.4567 46.0414 86.2874 46.5734C87.1274 47.0961 87.7621 47.8101 88.1914 48.7154C88.6207 49.6114 88.8354 50.6148 88.8354 51.7254C88.8354 52.2108 88.8167 52.6261 88.7794 52.9714C88.7421 53.3074 88.6767 53.6808 88.5834 54.0914H79.3014Z"
        fill="#003AA5"
      />
      <path
        d="M100.47 46.1254C100.47 46.5361 100.405 47.0308 100.274 47.6094C100.153 48.1788 99.9567 48.6828 99.6861 49.1214C99.4901 49.0654 99.2894 49.0281 99.0841 49.0094C98.8881 48.9814 98.6921 48.9674 98.4961 48.9674C97.9921 48.9674 97.5487 49.0701 97.1661 49.2754C96.7927 49.4714 96.4707 49.7374 96.2001 50.0734C95.9294 50.4001 95.6961 50.7734 95.5001 51.1934C95.3041 51.6041 95.1361 52.0194 94.9961 52.4394L94.5621 51.8794C94.5807 51.1981 94.6694 50.5121 94.8281 49.8214C94.9867 49.1214 95.2294 48.4821 95.5561 47.9034C95.8921 47.3154 96.3307 46.8441 96.8721 46.4894C97.4227 46.1348 98.0994 45.9574 98.9021 45.9574C99.1914 45.9574 99.4574 45.9714 99.7001 45.9994C99.9427 46.0274 100.199 46.0694 100.47 46.1254ZM91.3981 47.6234C91.4634 47.3341 91.6034 47.0261 91.8181 46.6994C92.0421 46.3634 92.2707 46.1114 92.5041 45.9434C93.5121 46.1208 94.2634 46.5921 94.7581 47.3574C95.2527 48.1134 95.5374 49.0748 95.6121 50.2414V59.9994C95.4067 60.0368 95.1641 60.0648 94.8841 60.0834C94.6041 60.1021 94.3194 60.1114 94.0301 60.1114C93.7501 60.1114 93.4701 60.1021 93.1901 60.0834C92.9101 60.0648 92.6581 60.0368 92.4341 59.9994V50.5074C92.4341 49.7421 92.3174 49.1214 92.0841 48.6454C91.8601 48.1694 91.6314 47.8288 91.3981 47.6234Z"
        fill="#003AA5"
      />
    </svg>
  );
}