import { useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import Scene from "./R3F/Scene";
import Landing from "./Components/Landing";
import PopUp from "./Components/PopUp";
import { useGLTF } from "@react-three/drei";
import LoadingScreen from "./LoadingScreen";
import { SRGBColorSpace } from "three";
import Info from "./Info";
import Sound from "./Components/Sound";
import ReactGA from 'react-ga';
import SoundManager from "./Components/SoundManager";

function App() {
  const [discover, setDiscover] = useState(false);
  const [cameraAnimationEnded, setCameraAnimationEnded] = useState(false);
  const [startExperience, setStartExperience] = useState(false);
  const [date, setDate] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [isCameraLocked, setIsCameraLocked] = useState(false);
  const soundManagerRef = useRef(null);



  useEffect(() => {
    // Initialiser Google Analytics

    const date = new Date();
    let todayDate = date.getDate() === 24 ? 24 : date.getDate() % 24;

    const searchParams = new URLSearchParams(window.location.search);

    // Vérifier si le paramètre 'day' est présent dans l'URL
    if (searchParams.has("day")) {
      const dayParam = parseInt(searchParams.get("day"), 10);
      // Vérifier que le jour est valide et ne dépasse pas le jour actuel
      if (!isNaN(dayParam) && dayParam >= 1 && dayParam <= 24 && dayParam <= todayDate) {
        todayDate = dayParam;
      }
    }

    setDate(todayDate);

    if (
      searchParams.has("debug") &&
      searchParams.get("debug") === "showCalendar"
    )
      setShowCalendar(true);
    soundManagerRef.current = new SoundManager();

  }, []);

  function onDiscoverClick() {
    soundManagerRef.current?.playSingleSound("opening")
    setDiscover(true)
  }

  function startXp(isStarting) {
    soundManagerRef.current?.startXp("start")
    setStartExperience(isStarting)
  }

  function animCamEnd(breed) {
    if (breed === "Chien") {
      soundManagerRef.current?.playSingleSound("bark")
    }
    else if (breed === "Chat") {
      soundManagerRef.current?.playSingleSound("meow")
    }
    setCameraAnimationEnded(true)
  }

  const handleCameraLockToggle = () => {
    setIsCameraLocked(prevState => !prevState);
  };

  return (
    <>
      {!discover && startExperience && (<Sound
        playStartSound={startExperience}
        playBarkSound={cameraAnimationEnded}
        playDiscoverSound={discover}
      />)}


      <button
        className="absolute rounded-full bg-[#D8D8FF] hover:bg-[#9191D0] top-[15px] left-4 rounded-full left-2 z-30 bg-[#D8D8FF] lg:top-[15px]  hover:bg-[#9191D0] text-[#003AA5] p-3 flex items-center"
        onClick={() => setCalendarVisible(!calendarVisible)}
      >
        <svg className="block" width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="10.7817" rx="2" fill="#003AA5" />
          <rect y="14.2539" width="36" height="21.7462" rx="2" fill="#003AA5" />
          <rect x="3.24023" y="18.3594" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
          <rect x="14.0391" y="18.3594" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
          <rect x="24.8398" y="18.3594" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
          <rect x="3.24023" y="26.2812" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
          <rect x="14.0391" y="26.2812" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
          <rect x="24.8398" y="26.2812" width="7.74" height="5.76" rx="2.88" fill="#D8D8FF" />
        </svg>
      </button>
      <section
        className={`absolute w-screen h-svh top-0 left-0 z-0 ${startExperience && !discover && ""
          } transition-[filter] duration-1000`}
      >
        <Canvas
          flat
          gl={{
            antialias: true,
            outputRendering: "srgb",
            outputColorSpace: SRGBColorSpace,
          }}
        >
          <Scene
            discover={discover}
            onCameraAnimationEnd={animCamEnd}
            date={date}
            isCameraLocked={isCameraLocked}
          />
        </Canvas>
      </section>
      <Landing
        onStartExperience={startXp}
        onDiscoverClick={onDiscoverClick}
        startExperience={startExperience}
        discover={discover}
        date={date}
      />
      {discover && (
        <PopUp
          cameraAnimationEnded={cameraAnimationEnded}
          date={date}
        />
      )}

      {(showCalendar || calendarVisible) && (
        <div className={`z-50 absolute bg-[#D8D8FF] p-2 rounded-xl top-[140px] left-4 grid grid-cols-7 gris-rows-flow gap-1 ${discover || !startExperience ? 'top-[80px] lg:top-[80px]' : ''}`}>
          <div className="col-span-7  text-[#003AA5] uppercase flex justify-center">
            <h4>Sélectionner un jour</h4>
          </div>
          <div className="col-span-6" />
          {[...Array(24)].map((value, index) => (
            <button
              key={index}
              onClick={() => {
                setDate(new Date().getDate() >= index + 1 ? index + 1 : date)
                setCalendarVisible(!calendarVisible)
              }}
              disabled={new Date().getDate() < index + 1}
              className={`${date === index + 1 && "outline outline-white"
                } text-white w-6 h-6 bg-[#003AA5] flex justify-center  rounded-md ${new Date().getDate() < index + 1 && "opacity-50"}`}
            >
              {index + 1}
            </button>
          ))}
          {discover && (
            <button
              onClick={handleCameraLockToggle}
              className={`col-span-7 mt-2 p-2 bg-[#003AA5] text-white rounded-md ${isCameraLocked ? 'bg-[#9191D0]' : 'bg-[#003AA5]'}`}
            >
              {isCameraLocked ? "Revenir à l'animation" : "Changer la vue"}
            </button>
          )}
        </div>
      )}
      <LoadingScreen />
      {!discover && (<Info />)}
    </>
  );
}

export default App;


useGLTF.preload("/gltf/refuge4.glb");