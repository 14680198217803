import { useEffect, useState } from "react";
import Dots from "./Elements/Dots";
import { Canvas } from "@react-three/fiber";
import AnimalCanvas from "./AnimalCanvas";
import Paw from "./Elements/Paw";

export default function Landing({ onStartExperience, onDiscoverClick, discover, startExperience, date }) {
  const [hoveredNumber, setHoveredNumber] = useState(-1);
  const [showLogo, setShowLogo] = useState(true);

  return (
    <section className="text-white w-full h-svh z-20 absolute flex flex-col justify-between items-center px-4 lg:px-10 2xl:px-14 gap-2">
      <div className="h-20 lg:h-28 2xl:h-36 mt-8 pb-4 grow-0">
        {showLogo && (
          <img
            alt="logo Fondation Brigitte Bardot"
            src="./png/logo-fondation.webp"
            className=" h-full w-auto "
          />
        )}
      </div>
      <div className="w-full grow min-h-0 grid grid-cols-1 grid-rows-1 h-svh overflow-hidden">
        <section
          className={`
          ${startExperience ? "opacity-0" : "opacity-100"
            } transition-opacity pb-8
          text-white w-full h-full col-start-1 row-start-1 flex flex-col justify-between items-center gap-2`}
        >
          <h1 className="text-stroke text-white text-4xl lg:text-6xl 2xl:text-7xl uppercase text-center">
            le calendrier
            <br />
            de l'avent
          </h1>
          <button
            className="uppercase flex items-center text-sm lg:text-base 2xl:text-xl gap-2"
            onClick={() => onStartExperience(true)}
          >
            <div className="w-6 lg:w-8 2xl:w-12">
              <Dots color="#fff" />
            </div>
            <span>commencer l'experience</span>
            <div className="w-6 lg:w-8 2xl:w-12">
              <Dots color="#fff" />
            </div>
          </button>
        </section>

        <section
          className={`col-start-1 row-start-1 text-white w-full h-full grow flex flex-col 
        justify-between items-center gap-2 z-20 pb-8 ${startExperience && !discover ? "translate-y-0" : "translate-y-full"
            } transition-transform duration-1000`}
        >
          <div className="grow min-h-0 w-full flex justify-center p-8">
            <div
              className="h-full w-full max-h-72 max-w-72 lg:max-h-80 lg:max-w-80 2xl:max-h-96 2xl:max-w-96 blob3
          border border-white border-4 lg:border-8 outline outline-1 outline-white outline-offset-8 bg-[#ffffff50] backdrop-blur-md"
            >
              <Canvas camera={{ fov: 30 }} className="blob3">
                <AnimalCanvas date={date} hoveredNumber={hoveredNumber} discover={discover} />
              </Canvas>
            </div>
          </div>
          <div className="h-44 2xl:h-48">
            <Paw
              onHovered={(number) => setHoveredNumber(number)}
              onClick={() => {
                onDiscoverClick(true);
                setShowLogo(false);
              }}
            />
          </div>
        </section>
      </div>
    </section>
  );
}
