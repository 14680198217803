import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { LoopOnce } from "three";

export default function CatModel() {


  const [animIndex, setAnimIndex] = useState(0);

  const group = useRef()
  const { nodes, materials, animations } = useGLTF("./gltf/Chat Anim2.glb")
  const { actions } = useAnimations(animations, group)
  const matcamanimals = useTexture("./textures/7A7A7A_D9D9D9_BCBCBC_B4B4B4.jpg")
  const catmap = useTexture("./textures/Fat_cat_calico_base.webp")
  catmap.flipY = false;

  useEffect(() => {
    actions["Sit"].loop = LoopOnce;
    actions["Sit"].clampWhenFinished = true;
    actions["static"].play();

    const interval = setInterval(
      () => setAnimIndex((animIndex) => animIndex + 1),
      5000
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (animIndex % 2 === 0) actions["Sit"].fadeOut(1);
    else actions["Sit"].reset().fadeIn(1).play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animIndex]);

  return (
    <>

      <group name="rig"
        scale={0.35}
        ref={group}
      >
        <skinnedMesh
          name="Mesh_Chat"
          geometry={nodes.Mesh_Chat.geometry}
          skeleton={nodes.Mesh_Chat.skeleton}
        >
          <meshMatcapMaterial matcap={matcamanimals} map={catmap} /></skinnedMesh>
        <primitive object={nodes.c_pos} />
        <primitive object={nodes.c_foot_ikr} />
        <primitive object={nodes.c_leg_poler} />
        <primitive object={nodes.c_foot_ikl} />
        <primitive object={nodes.c_leg_polel} />
        <primitive object={nodes.root_refx} />
        <primitive object={nodes.c_foot_ik_dupli_001l} />
        <primitive object={nodes.c_leg_pole_dupli_001l} />
        <primitive object={nodes.c_foot_ik_dupli_001r} />
        <primitive object={nodes.c_leg_pole_dupli_001r} />
      </group>
    </>
  );
}
