import { useCursor } from "@react-three/drei";
import gsap from "gsap";
import { useEffect, useState } from "react";

export default function VenezMeRencontrer() {
  const [hovered, setHovered] = useState(false);
  const [innerShadow, setInnerShadow] = useState(0);
  useCursor(hovered === 0);

  //When svg is hovered, add the inner shadow filter
  useEffect(() => {
    let is = { value: hovered ? 0 : 10 };
    gsap.to(is, {
      value: hovered ? 10 : 0,
      duration: 0.3,
      onUpdate: () => {
        setInnerShadow(is.value);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => gsap.killTweensOf(is);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 580.38 206.94"
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      className="z-30"
    >
      <filter id="svgFilter2">
        <feOffset dx={innerShadow * 0.5} dy={innerShadow * 0.5} />
        <feGaussianBlur stdDeviation={innerShadow} result="offset-blur" />
        <feComposite
          operator="out"
          in="SourceGraphic"
          in2="offset-blur"
          result="inverse"
        />
        <feFlood floodColor="#003aa5" floodOpacity="1" result="color" />
        <feComposite operator="in" in="color" in2="inverse" result="shadow" />
        <feComposite operator="over" in="shadow" in2="SourceGraphic" />
      </filter>
      <g filter="url(#svgFilter2)" id="b" data-name="background">
        <path
          d="m245.16,197.43c-82.35,0-147.46-8.67-188.3-25.07-19.79-7.95-33.5-17.42-40.76-28.17-6.27-9.28-7.4-19.41-3.37-30.12,4.23-11.23,14.17-23.13,28.75-34.41,15.52-12.01,35.64-23.02,59.8-32.72,37.54-15.08,101.52-33.63,193.14-36.33,9.1-.27,18.19-.4,27.01-.4,71.14,0,132.75,8.74,178.15,25.26,22.63,8.24,40.36,18.05,52.68,29.16,11.24,10.14,17.3,20.73,17.99,31.49.69,10.74-4.03,21.49-14.03,31.96-11.26,11.79-28.54,22.53-51.36,31.95-52.42,21.63-130.1,34.39-224.64,36.92-11.97.32-23.77.48-35.07.48Z"
          fill={hovered ? "#D8D8FF" : "#D8D8FF"}
          strokeWidth="0"
          className={`transition`}
        />
      </g>
      <g id="c" data-name="text">
        <text
          transform="translate(154.26 94.47)"
          fill="#003AA5"
          fontFamily="ADLaM Display"
          fontSize="35"
          fontWeight="400"
          className="drop-shadow-md"
        >
          <tspan x="-20" y="5">
            VENEZ ME
          </tspan>
          <tspan x="-20" y="45">RENCONTRER</tspan>
        </text>
      </g>
      <g id="d" data-name="paw" className="drop-shadow-md">
        <path
          d="m442.89,57.78c8.69-2.57,11.15,12.82,8.3,20.48-3.11,8.34-11.6,7.82-14.77,2.34,0,0-.57-.96-.68-1.55-2.35-7.24,1.75-19.67,7.16-21.27"
          fill="#003AA5"
          strokeWidth="0"
        />
        <path
          d="m455.78,101.33l-.02-.03-.02-.02s0-.01-.03-.05c-.02-.04-.06-.1-.1-.18-.08-.15-.17-.33-.26-.53-.2-.41-.36-.78-.39-.87-1.2-3.95-.11-8.87,1.89-12.92,1-2.02,2.21-3.79,3.45-5.09,1.25-1.32,2.47-2.08,3.46-2.22,1.81-.24,3.11.46,4.06,1.79.98,1.36,1.58,3.36,1.87,5.65.58,4.57-.1,9.99-1.15,12.83h0c-1.49,4-3.95,5.7-6.31,5.82-2.39.11-4.88-1.38-6.45-4.17Z"
          fill="#003AA5"
          strokeWidth="0"
        />
        <path
          d="m430.05,93.7c12.11-.63,14.86,11.37,17.82,16.79,5.3,9.7,11.75,8.93,15.24,14.84,4.77,8.08-2.16,18.44-11.4,17.29-6.49-.8-10.19-7.48-22.54-6.67-8.53.56-12.54,5.49-17.25,6.67-11.1,2.76-18.45-9.65-12.66-18.05,3.88-5.62,9.5-4.8,14.48-13.58,2.88-5.07,5.17-16.71,16.31-17.29h0Z"
          fill="#003AA5"
          strokeWidth="0"
        />
        <path
          d="m399.65,105.9c-2.36-.11-4.82-1.81-6.31-5.82h0c-1.06-2.84-1.74-8.26-1.15-12.83.29-2.28.89-4.29,1.87-5.65.95-1.32,2.25-2.03,4.06-1.79.99.13,2.21.9,3.46,2.22,1.24,1.3,2.45,3.07,3.45,5.09,2.01,4.05,3.09,8.98,1.89,12.92-.03.1-.2.47-.39.87-.09.19-.19.38-.26.53-.04.07-.07.13-.1.18-.02.04-.03.05-.03.05l-.02.02-.02.03c-1.57,2.79-4.06,4.29-6.45,4.17Z"
          fill="#003AA5"
          strokeWidth="0"
        />
        <path
          d="m418.96,58.17c5.4,1.6,9.51,14.03,7.16,21.27-.11.59-.68,1.55-.68,1.55-3.17,5.47-11.66,5.99-14.77-2.34-2.86-7.66-.4-23.05,8.3-20.48"
          fill="#003AA5"
          strokeWidth="0"
        />
      </g>
    </svg>
  );
}
