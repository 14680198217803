import { useProgress } from "@react-three/drei";
import { useEffect, useState } from "react";
import gsap from "gsap";
import Dots from "./Components/Elements/Dots";

export default function LoadingScreen() {
  const { progress } = useProgress();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (progress === 100) gsap.to("#loadingScreen", {
      autoAlpha: 0,
      delay: 0.5,
      duration: 1,
      onComplete: () => setShow(false)
    });
  }, [progress]);

  return (
    <>
      {show && (
        <div
          id="loadingScreen"
          className="pointer-events-none fixed w-screen h-svh bg-[#003aa5] z-50 flex flex-col items-center gap-4 justify-center"
        >
          <div className="h-20 lg:h-28 2xl:h-36 pb-4 grow-0">
            <img
              alt="logo Fondation Brigitte Bardot"
              src="./png/logo-fondation.webp"
              className=" h-full w-auto "
            />
          </div>
          <p className="pt-8 uppercase text-white text-xs sm:text-sm 3xl:text-base font-light text-center">
            chargement
          </p>
          <div className="w-4 h-auto lg:w-6 2xl:w-8 flex items-end">
            <Dots color="#fff" animate />
          </div>
        </div>
      )}
    </>
  );
}