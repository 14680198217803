import { useEffect, useRef, useState } from 'react';
import SoundManager from "./SoundManager";

function Sound() {
    const [isMuted, setIsMuted] = useState(true);
    const soundManagerRef = useRef(null);

    useEffect(() => {
        soundManagerRef.current = new SoundManager();
    }, []);

    const toggleMute = () => {
        soundManagerRef.current?.toggleSound();
        setIsMuted(prev => !prev);
    };

    return (
        <div className="fixed top-2 right-4 z-50">
            <button
                onClick={toggleMute}
                className="fixed top-[75px] left-4 w-12 h-12 rounded-full bg-[#D8D8FF] hover:bg-[#9191D0] text-white border-none shadow-lg cursor-pointer flex items-center justify-center transition-transform duration-300 ease-in-out z-10"
                aria-label={!isMuted ? "Activer le son" : "Désactiver le son"}
            >
                {!isMuted ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#003AA5" strokeWidth="2">
                        <path d="M11 5L6 9H2v6h4l5 4V5z" />
                        <line x1="23" y1="9" x2="17" y2="15" />
                        <line x1="17" y1="9" x2="23" y2="15" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#003AA5" strokeWidth="2">
                        <path d="M11 5L6 9H2v6h4l5 4V5z" />
                        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
                    </svg>
                )}
            </button>
        </div>
    );
};

export default Sound;